let preloader = document.querySelector(".preloader");

if (preloader) {
  let html = document.querySelector("html");
  html.classList.add("overflow-hidden");

  preloader.addEventListener("animationend", () => {
    if (html.classList.contains("overflow-hidden")) {
      if (preloader.classList.contains("preloader_start-animation")) {
        preloader.classList.remove("preloader_start-animation");
        preloader.classList.add("preloader_end-animation");
      } else {
        preloader.classList.remove("preloader_end-animation");
        setTimeout(() => {
          preloader.style.webkitAnimationName = "";
          preloader.classList.add("preloader_end-animation");
        }, 500);
      }
    }
  });

  document.addEventListener("DOMContentLoaded", () => {
    preloader.classList.remove("preloader_active");
    html.classList.remove("overflow-hidden");
  });
}
